import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => (
	<div className="d-flex flex-column align-items-center">
		<div className="mb-2">
			<StaticImage
				src="../images/logo_hc.png"
				loading="lazy"
				width={150}
				formats={["auto", "webp", "avif"]}
				alt="Healthycell"
				placeholder="none"
			/>
		</div>
		{/* <div>
			<div className="d-flex align-items-center">
				<b>Powered by</b> 
				<div className="ms-1">
				<StaticImage
					src="../images/lifedna-logo-header.png"
					loading="lazy"
					width={80}
					formats={["auto", "webp", "avif"]}
					alt="Life DNA"
					placeholder="none"
				/>
			</div>
			</div>
		</div>*/}
	</div>
)

export default Logo
